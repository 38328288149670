import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { submitQuery, clearQueryData } from '../store/querySlice';
import Feed from './Feed';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/SearchPage.module.css';
import ShowImage from './ShowImage';
import PersonImage from './PersonImage';
import { FaSearch } from 'react-icons/fa';

const SearchPage = () => {
  // Initialize state from URL params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialShows = new Set(params.get('shows_uuids')?.split(',').filter(Boolean) || []);
  const initialPersons = new Set(params.get('persons_uuids')?.split(',').filter(Boolean) || []);

  const [query, setQuery] = useState('');
  const [selectedShows, setSelectedShows] = useState(initialShows);
  const [selectedPersons, setSelectedPersons] = useState(initialPersons);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [resultCountWidth, setResultCountWidth] = useState(0);
  const resultCountRef = useRef(null);
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.query);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authLoading) {
      if (!isAuthenticated) {
        localStorage.setItem('redirectAfterAuth', location.pathname + location.search);
        navigate('/auth?from=' + encodeURIComponent(location.pathname + location.search));
      }
    }
  }, [isAuthenticated, authLoading, navigate, location]);

  useEffect(() => {
    return () => {
      dispatch(clearQueryData());
    };
  }, [dispatch]);

  // Extract unique shows and persons from the data
  const { shows, persons } = useMemo(() => {
    if (!data?.excerpts) return { shows: [], persons: [] };
    
    const showsSet = new Set();
    const personsSet = new Set();
    
    data.excerpts.forEach(excerpt => {
      if (excerpt.show) {
        showsSet.add(JSON.stringify({ id: excerpt.show.shows_uuid, name: excerpt.show.title, image: excerpt.show.shows_image }));
      }
      if (excerpt.guests) {
        excerpt.guests.forEach(guest => {
          personsSet.add(JSON.stringify({ 
            id: guest.persons_uuid, 
            name: guest.name, 
            image: guest.public_image_url 
          }));
        });
      }
    });

    return {
      shows: Array.from(showsSet).map(show => JSON.parse(show)),
      persons: Array.from(personsSet).map(person => JSON.parse(person))
    };
  }, [data]);

  // Filter the excerpts based on selected filters
  const filteredExcerpts = useMemo(() => {
    if (!data?.excerpts) return [];
    if (selectedShows.size === 0 && selectedPersons.size === 0) return data.excerpts;

    return data.excerpts.filter(excerpt => {
      const showMatch = selectedShows.size === 0 || (excerpt.show && selectedShows.has(excerpt.show.shows_uuid));
      const personMatch = selectedPersons.size === 0 || 
        (excerpt.guests && excerpt.guests.some(guest => selectedPersons.has(guest.persons_uuid)));
      return showMatch && personMatch;
    });
  }, [data, selectedShows, selectedPersons]);

  // Add new effect to remove shows with no matching results
  useEffect(() => {
    if (selectedShows.size > 0 && (selectedShows.size > 1 || selectedPersons.size > 0)) {
      const showsWithResults = new Set(
        filteredExcerpts
          .filter(excerpt => excerpt.show)
          .map(excerpt => excerpt.show.shows_uuid)
      );

      setSelectedShows(prev => {
        const next = new Set(prev);
        let changed = false;
        prev.forEach(showId => {
          if (!showsWithResults.has(showId)) {
            next.delete(showId);
            changed = true;
          }
        });
        return changed ? next : prev;
      });
    }
  }, [filteredExcerpts, selectedShows, selectedPersons]);

  // Add this effect to measure the result count width
  useEffect(() => {
    if (resultCountRef.current) {
      const width = resultCountRef.current.getBoundingClientRect().width;
      setResultCountWidth(width + 24); // Add 24px padding
    }
  }, [filteredExcerpts.length, selectedShows.size, selectedPersons.size]);

  const toggleShow = (showId) => {
    setSelectedShows(prev => {
      const next = new Set(prev);
      if (next.has(showId)) {
        next.delete(showId);
      } else {
        next.add(showId);
      }
      return next;
    });
  };

  const togglePerson = (personId) => {
    setSelectedPersons(prev => {
      const next = new Set(prev);
      if (next.has(personId)) {
        next.delete(personId);
      } else {
        next.add(personId);
      }
      return next;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      setIsInputFocused(false);
      dispatch(submitQuery(query));
    }
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <PageHelmet title="Search" />
      <div className={styles.searchContainer}>
        <form onSubmit={handleSubmit} className={styles.searchForm}>
          <div className={styles.searchInputWrapper}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              placeholder="Search everything..."
              className={styles.searchInput}
              style={!isInputFocused ? { paddingRight: `${resultCountWidth}px` } : undefined}
              autoFocus
            />
            {status === 'succeeded' && data && !isInputFocused && (
              <span className={styles.resultCount} ref={resultCountRef}>
                {filteredExcerpts.length} {(selectedShows.size > 0 || selectedPersons.size > 0) ? 'filtered ' : ''}
                {filteredExcerpts.length === 1 ? 'result' : 'results'}
              </span>
            )}
          </div>
          <button 
            type="submit" 
            className={styles.searchButton}
            disabled={status === 'loading'}
          >
            <span className={styles.searchButtonText}>Search Pod Nebula</span>
            <FaSearch className={styles.searchIcon} />
          </button>
        </form>

        <div className={styles.searchContent}>
          {status === 'loading' && <LoadingSpinner text="Searching..." />}
          
          {status === 'failed' && <div className={styles.error}>Error: {error}</div>}
          
          {status === 'succeeded' && data && (
            <>
              {(persons.length > 0 || shows.length > 0) && (
                <div className={styles.filterSection}>
                  <div className={styles.filterOptions}>
                  {persons.map(person => (
                      <div
                        key={person.id}
                        className={`${styles.filterOption} ${selectedPersons.has(person.id) ? styles.selected : ''}`}
                        onClick={() => togglePerson(person.id)}
                      >
                        <PersonImage src={person.image} alt={person.name} size={24} />
                        <span className={styles.filterOptionLabel}>{person.name}</span>
                      </div>
                    ))}
                    {shows.map(show => (
                      <div
                        key={show.id}
                        className={`${styles.filterOption} ${selectedShows.has(show.id) ? styles.selected : ''}`}
                        onClick={() => toggleShow(show.id)}
                      >
                        <ShowImage src={show.image} alt={show.name} size={24} />
                        <span className={styles.filterOptionLabel}>{show.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              <Feed excerpts={filteredExcerpts} />
              
              {filteredExcerpts.length === 0 && (
                <div className={styles.noResults}>No results found</div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPage;
