import React, { useState } from 'react';
import { FaEllipsisV, FaShareSquare, FaGlobe, FaCompass } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExcerptAudioPlayer from './ExcerptAudioPlayer';
import styles from '../styles/ExcerptImageContainer.module.css';

const ExcerptImageContainer = ({
  episode,
  show,
  currentlyPlayingExcerpt,
  onTimeUpdate,
  seekTo,
  isPlaying,
  onNextExcerpt,
  onPreviousExcerpt,
  onDiveIntoEpisode = null,
  onAudioEnded = null,
  showShareButton = true
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleShareClick = () => {
    let currentUrl = window.location.href;
    if (currentlyPlayingExcerpt) {
      currentUrl = window.location.origin + `/excerpts/${currentlyPlayingExcerpt.excerpts_uuid}`;
    }

    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Episode Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
    setIsMenuOpen(false);
  };

  const handleSourceClick = () => {
    if (episode?.source_episode_url) {
      window.open(episode.source_episode_url, '_blank');
    } else {
      toast.error('Source URL not available', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastError,
      });
    }
    setIsMenuOpen(false);
  };

  const imageUrl = imageError || !episode?.episode_image 
    ? '/placeholder.webp' 
    : episode.episode_image;

    const validAudioUrl = currentlyPlayingExcerpt?.source_audio_url || episode.source_audio_url;

    if (!validAudioUrl) {
        console.warn(`Excerpt ${currentlyPlayingExcerpt?.excerpts_uuid} is missing a valid audio URL.`);
    }

  return (
    <div className={styles.episodeImageContainer}>
      <img 
        className={styles.episodeImage} 
        src={imageUrl}
        alt={episode?.title || 'Episode image'} 
        onError={handleImageError}
      />
      
      {onDiveIntoEpisode && (
        <button 
          className={styles.diveIntoEpisodeButton}
          onClick={onDiveIntoEpisode}
        >
          Dive into episode <FaCompass className={styles.arrowIcon} />
        </button>
      )}

      {showShareButton && (
        <div className={styles.menuContainer}>
          <button 
            className={styles.menuButton}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FaEllipsisV />
          </button>
          {isMenuOpen && (
            <div className={styles.menuDropdown}>
              <button 
                className={styles.menuItem}
                onClick={handleShareClick}
              >
                <FaShareSquare className={styles.menuIcon} />
                Share
              </button>
              <button 
                className={styles.menuItem}
                onClick={handleSourceClick}
              >
                <FaGlobe className={styles.menuIcon} />
                Source
              </button>
            </div>
          )}
        </div>
      )}

      <div className={styles.audioPlayerOverlay}>
        {validAudioUrl && (
          <ExcerptAudioPlayer 
            sourceAudioUrl={validAudioUrl}
            onTimeUpdate={onTimeUpdate}
            seekTo={seekTo}
            isPlaying={isPlaying}
            onNextExcerpt={onNextExcerpt}
            onPreviousExcerpt={onPreviousExcerpt}
            title={currentlyPlayingExcerpt ? currentlyPlayingExcerpt.title : episode.title}
            artist={show?.title}
            artwork={episode.episode_image}
            onAudioEnded={onAudioEnded}
            startTime={currentlyPlayingExcerpt?.timestamp_start_seconds || 0}
            duration={currentlyPlayingExcerpt?.excerpt_length_seconds || 0}
          />
        )}
      </div>
    </div>
  );
};

export default ExcerptImageContainer;
