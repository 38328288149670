import React, { useState } from 'react';
import { FaPlay, FaTrash, FaSpinner } from 'react-icons/fa';
import styles from '../styles/QueueExcerptsTable.module.css';
import Pagination from './Pagination';

const QueueExcerptsTable = ({ 
  excerpts, 
  onSelectExcerpt, 
  pagination, 
  onPageChange, 
  onExcerptRemoved,
  deletingExcerpts
}) => {
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (excerptUuid) => {
    setImageErrors(prev => ({
      ...prev,
      [excerptUuid]: true
    }));
  };

  const handlePersonSelect = (personsUuid) => {
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  const handleShowSelect = (showsUuid) => {
    window.open(`/shows/${showsUuid}`, '_blank');
  };

  const handleDelete = async (uuid) => {
    try {
      await onExcerptRemoved?.(uuid);
    } catch (error) {
      throw error;
    }
  };

  const sortedExcerpts = [...excerpts].sort((a, b) => {
    const dateA = new Date(a.added_datetime_utc_iso_str);
    const dateB = new Date(b.added_datetime_utc_iso_str);
    return dateB - dateA;
  });

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {sortedExcerpts.map((excerpt, index) => {
              const uuid = excerpt.excerpt.excerpts_uuid;
              const isDeleting = deletingExcerpts.includes(uuid);
              const imageUrl = imageErrors[uuid] || !excerpt.episode?.episode_image
                ? '/placeholder.webp'
                : excerpt.episode.episode_image;
              
              return (
                <tr key={uuid}>
                  <td className={styles.imageCell}>
                    <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                      <img 
                        src={imageUrl}
                        alt={excerpt.excerpt.title} 
                        className={styles.thumbnailImage}
                        onError={() => handleImageError(uuid)}
                      />
                      <div className={styles.playOverlay}>
                        <FaPlay className={styles.playIcon} />
                      </div>
                    </div>
                  </td>
                  <td className={styles.contentCell}>
                    <span 
                      className={styles.title}
                      onClick={() => onSelectExcerpt(excerpt, index)}
                    >
                      {excerpt.excerpt.title}
                    </span>
                    <div className={styles.metaInfo}>
                      <span 
                        className={styles.showLink}
                        onClick={() => handleShowSelect(excerpt.show.shows_uuid)}
                      >
                        {excerpt.show.title}
                      </span>
                      {excerpt.guests.length > 0 && (
                        <div className={styles.guestInfo}>
                          <span 
                            className={styles.guestLink}
                            onClick={() => handlePersonSelect(excerpt.guests[0].persons_uuid)}
                          >
                            {excerpt.guests[0].name}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  {onExcerptRemoved ? (
                    <td className={styles.deleteCell}>
                      <button 
                        className={`${styles.deleteButton} ${isDeleting ? styles.deletingButton : ''}`}
                        onClick={() => handleDelete(uuid)}
                        disabled={isDeleting}
                        aria-label="Remove from queue"
                      >
                        {isDeleting ? (
                          <FaSpinner className={styles.spinningIcon} />
                        ) : (
                          <FaTrash />
                        )}
                      </button>
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          currentPage={pagination.page}
          totalItems={pagination.total}
          itemsPerPage={pagination.limit}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default QueueExcerptsTable;
