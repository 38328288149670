import axios from 'axios';
import { auth } from '../firebase';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor that adds the JWT token to the request headers
api.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Wrapper function for API calls
const apiCall = async (method, url, data = null) => {
  try {
    const response = await api[method](url, data);
    return response.data;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};


export const getEpisodeByUUID = (uuid) => apiCall('get', `/episodes/${uuid}`);
export const getEpisodes = () => apiCall('get', '/episodes');
export const getFeed = (filter) => {
  const { type, uuid } = filter || {};
  if (type && uuid) {
    return apiCall('get', `/feed?${type}=${uuid}`);
  }
  return apiCall('get', '/feed');
};
export const healthCheck = () => apiCall('get', '/healthcheck');
export const getExcerptByUUID = (uuid) => apiCall('get', `/excerpts/${uuid}`);
export const getPersonByUUID = (uuid) => apiCall('get', `/persons/${uuid}`);
export const getAllShows = (params = {}) => {
  const queryParams = new URLSearchParams({
    ...(params.query && { query: params.query }),
    limit: params.limit || 10,
    page: params.page || 1
  }).toString();
  
  return apiCall('get', `/shows${queryParams ? `?${queryParams}` : ''}`);
};
export const getShowByUUID = (uuid) => apiCall('get', `/shows/${uuid}`);

export const getUserPreferences = () => apiCall('get', '/user/preferences');

export const postQuery = (queryString) => apiCall('post', '/query', { query: queryString });

export const getShowEpisodes = (uuid, params = {}) => {
  const queryParams = new URLSearchParams({
    type: params.episodeType || 'all',
    limit: params.numEpisodes || 10,
    page: params.pageNumber || 1
  }).toString();
  
  return apiCall('get', `/shows/${uuid}/episodes?${queryParams}`);
};

export const getPersonEpisodes = (uuid, params = {}) => {
  const queryParams = new URLSearchParams({
    type: params.episodeType || 'all',
    limit: params.numEpisodes || 10,
    page: params.pageNumber || 1
  }).toString();
  
  return apiCall('get', `/persons/${uuid}/episodes?${queryParams}`);
};

export const getShowTopics = (uuid, params = {}) => {
  const queryParams = new URLSearchParams({
    limit: params.numTopics || 10,
    page: params.pageNumber || 1
  }).toString();
  
  return apiCall('get', `/shows/${uuid}/topics?${queryParams}`);
};

export const postExploreResults = (params) => {
  return apiCall('post', '/explore', {
    query: params.query,
    limit: params.limit || 10,
    page: params.page || 1,
    shows: params.shows || [],
    topics: params.topics || [],
    persons: params.persons || []
  });
};

export const getAllPersons = (params = {}) => {
  const searchParams = new URLSearchParams();
  
  if (params.query) searchParams.append('query', params.query);
  searchParams.append('limit', params.limit || 10);
  searchParams.append('page', params.page || 1);
  
  // Handle shows_uuids array properly
  if (params.shows_uuids && Array.isArray(params.shows_uuids)) {
    params.shows_uuids.forEach(uuid => {
      searchParams.append('shows_uuids', uuid);
    });
  }
  
  // Handle topics array properly
  if (params.topics && Array.isArray(params.topics)) {
    params.topics.forEach(uuid => {
      searchParams.append('topics_uuids', uuid);
    });
  }
  
  return apiCall('get', `/persons${searchParams.toString() ? `?${searchParams.toString()}` : ''}`);
};

export const getTagByUUID = (uuid) => apiCall('get', `/tags/${uuid}`);

export const getPersonTopics = (uuid, params = {}) => {
  const queryParams = new URLSearchParams({
    limit: params.numTopics || 10,
    page: params.pageNumber || 1
  }).toString();
  
  return apiCall('get', `/persons/${uuid}/topics?${queryParams}`);
};

export const requestEpisodeIngestion = (uuid) => 
  apiCall('post', `/episodes/${uuid}`, { action: 'request-ingestion' });

export const submitFeedback = (data) => apiCall('post', '/feedback', {
  feedback: data.feedback,
  current_url: data.currentUrl,
});

export const getTrendingTopics = (params = {}) => {
  const searchParams = new URLSearchParams({
    limit: params.limit || 10  // default fallback of 10 if not specified
  });
  
  // Handle shows_uuids array properly
  if (params.shows_uuids && Array.isArray(params.shows_uuids)) {
    params.shows_uuids.forEach(uuid => {
      searchParams.append('shows_uuids', uuid);
    });
  }
  
  return apiCall('get', `/trending${searchParams.toString() ? `?${searchParams.toString()}` : ''}`);
};

export const getTrendingTopicByUUID = (uuid) => apiCall('get', `/trending/${uuid}`);

export const getRandomTags = (params = {}) => {
  const queryParams = new URLSearchParams({
    limit: params.limit || 10
  }).toString();
  
  return apiCall('get', `/tags${queryParams ? `?${queryParams}` : ''}`);
};

export const getUserQueue = (params = {}) => {
  const queryParams = new URLSearchParams({
    limit: params.limit || 10,
    page: params.page || 1
  }).toString();
  
  return apiCall('get', `/user/queue${queryParams ? `?${queryParams}` : ''}`);
};

export const getUserHistory = (params = {}) => {
  const queryParams = new URLSearchParams({
    limit: params.limit || 10,
    page: params.page || 1
  }).toString();
  
  return apiCall('get', `/user/history${queryParams ? `?${queryParams}` : ''}`);
};

export const addToQueue = (excerptUuid) => 
  apiCall('post', '/user/queue', { excerpts_uuid: excerptUuid });

export const addToListenHistory = (params) => {
  const payload = {};
  if (params.excerptUuid) {
    payload.excerpts_uuid = params.excerptUuid;
  } else if (params.episodeUuid) {
    payload.episodes_uuid = params.episodeUuid;
  }
  return apiCall('post', '/user/history', payload);
};

export const removeFromQueue = (params) => {
  const payload = {};
  if (params.excerptUuid) {
    payload.excerpts_uuid = params.excerptUuid;
  } else if (params.episodeUuid) {
    payload.episodes_uuid = params.episodeUuid;
  }
  return apiCall('delete', '/user/queue', { data: payload });
};

export default api;
