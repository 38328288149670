import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCalendar, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { BiAddToQueue, BiInfoCircle } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExcerptTags from './ExcerptTags';
import MobileExcerptItem from './MobileExcerptItem';
import EntityList from './EntityList';
import EpisodeSponsor from './EpisodeSponsor';
import { fetchEpisodeByUUID, clearEpisodeData } from '../store/episodeInfoSlice';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import styles from '../styles/MobilePlayer.module.css';
import Person from './Person';
import PageHelmet from './PageHelmet';
import SafeHTML from './SafeHTML';
import EpisodeImageContainer from './EpisodeImageContainer';
import { requestEpisodeIngestion, addToListenHistory } from '../api/api';
import IngestionInfoModal from './IngestionInfoModal';

const MobilePlayer = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: episodeData, loading, error } = useSelector(state => state.episodeInfo);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [episode, setEpisode] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [seekTo, setSeekTo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentlyPlayingExcerpt, setCurrentlyPlayingExcerpt] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showIngestionInfo, setShowIngestionInfo] = useState(false);
  const [hasTrackedPlay, setHasTrackedPlay] = useState(false);

  const [excerpts, setExcerpts] = useState({
    all: [],
    filtered: [],
    enabled: [],
  });

  useEffect(() => {
    if (episodeData && !hasTrackedPlay) {
      setHasTrackedPlay(true);
      // Fire and forget - no need to await or handle errors
      addToListenHistory({ episodeUuid: episodeData.episode.episodes_uuid }).catch(() => {});
    }
  }, [episodeData, hasTrackedPlay]);

  useEffect(() => {
    if (!authLoading && isAuthenticated && uuid) {
      dispatch(fetchEpisodeByUUID(uuid));
    }

    // Cleanup function
    return () => {
      // Reset all state variables
      setEpisode(null);
      setSelectedTags([]);
      setSeekTo(null);
      setIsPlaying(false);
      setCurrentlyPlayingExcerpt(null);
      setExcerpts({
        all: [],
        filtered: [],
        enabled: [],
      });
      setHasTrackedPlay(false);

      // Clear episode data from Redux store
      dispatch(clearEpisodeData());
    };
  }, [uuid, dispatch, isAuthenticated, authLoading]);

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      localStorage.setItem('redirectAfterAuth', location.pathname);
      navigate('/auth?from=' + encodeURIComponent(location.pathname));
    }
  }, [isAuthenticated, authLoading, navigate, location]);

  useEffect(() => {
    if (episodeData) {
        // Create a new object for the episode to allow sorting
      setEpisode({...episodeData});
      
      // Create new arrays for excerpts
      const allExcerpts = episodeData.excerpts.map(excerpt => ({...excerpt})).sort((a, b) => a.timestamp_start_seconds - b.timestamp_start_seconds);
      
      setExcerpts({
        all: allExcerpts,
        filtered: allExcerpts,
        enabled: allExcerpts,
      });
      if (allExcerpts.length > 0) {
        setCurrentlyPlayingExcerpt(allExcerpts[0]);
      }
      else {
        setCurrentlyPlayingExcerpt(null);
      }
    }
  }, [episodeData]);

  useEffect(() => {
    if (excerpts.all.length > 0) {
      if (selectedTags.length === 0) {
        setExcerpts(prev => ({ ...prev, filtered: prev.all }));
      } else {
        const newFilteredExcerpts = excerpts.all.filter(excerpt =>
          excerpt.tags.some(tag => selectedTags.includes(tag.name))
        );
        setExcerpts(prev => ({ ...prev, filtered: newFilteredExcerpts }));
      }
    }
  }, [selectedTags, excerpts.all]);

  const handleTagClick = (tag, addOnly) => {
    setSelectedTags(prev => {
      if (prev.includes(tag) && !addOnly) {
        return prev.filter(t => t !== tag);
      } else {
        return [...prev, tag];
      }
    });
  };

  const handleShowAll = () => {
    setSelectedTags([]);
  };

  const handleSaveExcerpts = () => {
    setExcerpts(prev => ({ 
      ...prev, 
      enabled: [...prev.filtered]
    }));
    setSeekTo(0);
    setIsPlaying(true);
  };

  useEffect(() => {
  }, [excerpts]);

  const findNextEnabledExcerpt = useCallback((currentTime) => {
    if (!episode || !excerpts.all.length) {
        return null;
    }

    const nextExcerpt = excerpts.all.find(excerpt => 
        excerpt.timestamp_start_seconds > currentTime && 
        excerpts.enabled.includes(excerpt)
    );

    return nextExcerpt;
  }, [episode, excerpts.all, excerpts.enabled]);

  const handleTimeUpdate = useCallback((time) => {
    const currentExcerpt = excerpts.all.find(excerpt => 
      time >= excerpt.timestamp_start_seconds && 
      time < excerpt.timestamp_start_seconds + excerpt.excerpt_length_seconds &&
      excerpts.enabled.some(e => e.excerpts_uuid === excerpt.excerpts_uuid)
    );
    if (currentExcerpt && currentExcerpt.excerpts_uuid !== currentlyPlayingExcerpt?.excerpts_uuid) {
      setCurrentlyPlayingExcerpt(currentExcerpt);
    }

    // Only seek to the next excerpt if we're not currently playing one
    if (!currentExcerpt) {
      const nextExcerpt = findNextEnabledExcerpt(time);
      if (nextExcerpt) {
        setSeekTo(nextExcerpt.timestamp_start_seconds);
        setIsPlaying(true);
      }
    }
  }, [excerpts.all, excerpts.enabled, findNextEnabledExcerpt]);

  const handleOnListenSelect = useCallback((excerpt) => {
    setExcerpts(prev => {
      // Only add if not already in enabled list
      if (!prev.enabled.some(e => e.excerpts_uuid === excerpt.excerpts_uuid)) {
        return { ...prev, enabled: [...prev.enabled, excerpt] };
      }
      return prev;
    });
    setCurrentlyPlayingExcerpt(excerpt);
    setSeekTo(excerpt.timestamp_start_seconds);
    setIsPlaying(true);
  }, []);

  const handlePlayNextExcerpt = useCallback(() => {
    if (currentlyPlayingExcerpt) {
      const currentIndex = excerpts.all.findIndex(excerpt => excerpt.excerpts_uuid === currentlyPlayingExcerpt.excerpts_uuid);
      const nextExcerpt = excerpts.all[currentIndex + 1];
      if (nextExcerpt) {
        setSeekTo(nextExcerpt.timestamp_start_seconds);
        setCurrentlyPlayingExcerpt(nextExcerpt);
        setIsPlaying(true);
      } else {
        // If there's no next excerpt, stop playback
        setIsPlaying(false);
      }
    } else if (excerpts.all.length > 0) {
      // If no excerpt is currently playing, start with the first one
      setSeekTo(excerpts.all[0].timestamp_start_seconds);
      setCurrentlyPlayingExcerpt(excerpts.all[0]);
      setIsPlaying(true);
    }
  }, [currentlyPlayingExcerpt, excerpts.all]);

  const handlePreviousExcerpt = useCallback(() => {
    if (currentlyPlayingExcerpt) {
      const currentIndex = excerpts.enabled.findIndex(excerpt => excerpt.excerpts_uuid === currentlyPlayingExcerpt.excerpts_uuid);
      if (currentIndex > 0) {
        const previousExcerpt = excerpts.enabled[currentIndex - 1];
        setSeekTo(previousExcerpt.timestamp_start_seconds);
        setCurrentlyPlayingExcerpt(previousExcerpt);
        setIsPlaying(true);
      }
    }
  }, [currentlyPlayingExcerpt, excerpts.enabled]);

  const getCurrentExcerptIndex = useCallback(() => {
    if (currentlyPlayingExcerpt) {
      return excerpts.enabled.findIndex(excerpt => excerpt.excerpts_uuid === currentlyPlayingExcerpt.excerpts_uuid) + 1;
    }
    return 0;
  }, [currentlyPlayingExcerpt, excerpts.enabled]);

  const handleShareClick = () => {
    let currentUrl = window.location.href;
    if (currentlyPlayingExcerpt) {
      currentUrl = window.location.origin + `/excerpts/${currentlyPlayingExcerpt.excerpts_uuid}`;
    }

    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Episode Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
    setIsMenuOpen(false);
  };

  const handleSourceClick = () => {
    if (episode && episode.episode && episode.episode.source_episode_url) {
      window.open(episode.episode.source_episode_url, '_blank');
    } else {
      toast.error('Source URL not available', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastError,
      });
    }
    setIsMenuOpen(false);
  };

  const handleCurrentlyPlayingTagSelected = (tagName, tag) => {
    window.open(`/explore?tags_uuid=${tag.tags_uuid}`, '_blank');
  };

  const handleRequestIngestion = async () => {
    try {
      await requestEpisodeIngestion(uuid);
      toast.success('Episode added to ingestion queue', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    } catch (error) {
      toast.error('Failed to request episode ingestion', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastError,
      });
    }
  };

  const renderContent = () => {
    if (authLoading) {
      return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
      return null;
    }

    if (loading) {
      return <LoadingSpinner text="Loading episode..." />;
    }

    if (error) return <div>Error: {error}</div>;
    if (!episode || !episode.episode) return <div>No episode found</div>;

    const tagCounts = {};
    excerpts.all.forEach(excerpt => {
      excerpt.tags.forEach(tag => {
        tagCounts[tag.name] = (tagCounts[tag.name] || 0) + 1;
      });
    });

    return (
      <div className={`bg-gray-900 text-gray-100 font-sans ${styles.playerContainer}`}>
        <ToastContainer
          toastClassName={styles.customToast}
          progressClassName={styles.customProgress}
        />
        {episode && episode.episode && (
          <PageHelmet title={episode.episode.title} />
        )}
        <main className="container mx-auto px-4 py-8 md:py-16">
          <div className={styles.responsivePlayerLayout}>
            <div className={styles.playerMainContent}>
              <div className={styles.episodeHeader}>
                <div className={styles.episodeInfo}>
                  <h2 className={styles.episodeTitle}>{episode.episode.title || 'Untitled Episode'}</h2>
                  <h3 className={styles.showTitle}>
                    <Link to={`/shows/${episode.show?.shows_uuid}`}>
                      {episode.show?.title || 'Unknown Show'}
                    </Link>
                  </h3>
                  {episode.episode.published_date && (
                    <p className={styles.publishedDate}>
                      <FaCalendar className={styles.calendarIcon} />
                      {new Date(episode.episode.published_date).toLocaleDateString()}
                    </p>
                  )}
                  <div className={styles.guestsAndMenu}>
                    <div className={styles.personList}>
                      {episode.guests && episode.guests.length > 0 && (
                        episode.guests.map((person) => (
                          <Person key={person.persons_uuid} person={person} size={40} />
                        ))
                      )}
                    </div>
                  </div>
                </div>
                <div>
                <EpisodeImageContainer 
                  episode={episode.episode}
                  show={episode.show}
                  currentlyPlayingExcerpt={currentlyPlayingExcerpt}
                  onTimeUpdate={handleTimeUpdate}
                  seekTo={seekTo}
                  isPlaying={isPlaying}
                  onNextExcerpt={handlePlayNextExcerpt}
                  onPreviousExcerpt={handlePreviousExcerpt}
                />
                {!episode.episode.is_ingested && (
                  <div className={styles.ingestionControls}>
                    <button 
                      onClick={handleRequestIngestion}
                      className={styles.ingestionButton}
                    >
                      <BiAddToQueue className={styles.ingestionIcon} /> Request Ingestion
                    </button>
                    <button 
                      onClick={() => setShowIngestionInfo(true)}
                      className={styles.infoButton}
                    >
                      <BiInfoCircle />
                    </button>
                  {showIngestionInfo && (
                    <IngestionInfoModal onClose={() => setShowIngestionInfo(false)} />
                  )}
                </div>
              )}
                </div>
              </div>
              {episode.excerpts.length === 0 && (
                <div className={styles.currentlyPlayingContainer}>
                  <h4 className={styles.currentlyPlayingTitle}>Episode Description</h4> 
                  <SafeHTML html={episode.episode.description} />
                </div>
              )}

              {currentlyPlayingExcerpt !== null && currentlyPlayingExcerpt.entities.length > 0 && (
                <div className={styles.currentlyPlayingContainer}>
                  <h4 className={styles.currentlyPlayingTitle}>Pull It Up Jamie</h4> 
                  <EntityList entities={currentlyPlayingExcerpt.entities} />
                </div>
              )}
              {currentlyPlayingExcerpt !== null && (
                <div className={styles.currentlyPlayingContainer}>
                  <h4 className={styles.currentlyPlayingTitle}>Currently Playing</h4> 
                  <MobileExcerptItem 
                    key={`currentlyPlayingExcerpt-${currentlyPlayingExcerpt.excerpts_uuid}`} 
                    excerpt={currentlyPlayingExcerpt} 
                    onTagSelect={handleCurrentlyPlayingTagSelected}
                    onListenExcerpt={() => {}}
                    useShortSummary={false}
                  />
                  <div className={styles.excerptCounter}>
                    {getCurrentExcerptIndex()} / {excerpts.enabled.length}
                  </div>
                  <div className={styles.navigationButtons}>
                    <button 
                      onClick={handlePreviousExcerpt} 
                      className={styles.navButton}
                      disabled={excerpts.enabled.indexOf(currentlyPlayingExcerpt) === 0}
                    >
                      <FaArrowLeft /> Previous
                    </button>
                    <button 
                      onClick={handlePlayNextExcerpt} 
                      className={styles.navButton}
                      disabled={excerpts.enabled.indexOf(currentlyPlayingExcerpt) === excerpts.enabled.length - 1}
                    >
                      Next <FaArrowRight />
                    </button>
                  </div>
                </div>
              )}

            {(episode !== null && episode.episode.sponsors.length > 0) && (
                <div className={styles.currentlyPlayingContainer}>
                <h4 className={styles.sponsorsTitle}>Support this Podcast</h4> 
                {episode.episode.sponsors && episode.episode.sponsors.length > 0 && (
                      <EpisodeSponsor sponsors={episode.episode.sponsors} />
                    )}
                </div>
              )}
            </div>

            {excerpts.all.length > 0 && (<div className={styles.excerptsContainer}>
                <h4 className={styles.excerptTitle}>Episode Playlist</h4>
                <button className={styles.saveButton} onClick={handleSaveExcerpts}>Update Episode Playlist</button>
              <div className={styles.tagsContainer}>
                <ExcerptTags
                    tagCounts={tagCounts}
                    selectedTags={selectedTags}
                    onTagClick={handleTagClick}
                    onShowAll={handleShowAll}
                    notShowTitle={true}
                />
              </div>
              <h4 className={styles.excerptTitle}>Excerpts ({excerpts.filtered.length})</h4>
              <ul className={styles.excerptsList}>
                {excerpts.filtered.map((excerpt) => (
                  <li key={excerpt.excerpts_uuid} className={styles.excerptItemWrapper}>
                    <MobileExcerptItem 
                      key={excerpt.excerpts_uuid} 
                      excerpt={excerpt} 
                      onTagSelect={handleTagClick}
                      onListenExcerpt={() => handleOnListenSelect(excerpt)}
                      isPlaying={excerpt.excerpts_uuid === currentlyPlayingExcerpt?.excerpts_uuid}
                      useShortSummary={true}
                    />
                  </li>
                ))}
              </ul>
            </div>)}
          </div>
        </main>
      </div>
    );
  };

  return renderContent();
};

export default MobilePlayer;
