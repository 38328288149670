import React from 'react';
import styles from '../styles/TopicsList.module.css';
import LoadingSpinner from './LoadingSpinner';
import Pagination from './Pagination';
import { getTagColor, getContrastColor } from '../utils/utils';

const TopicsList = ({
  title,
  topics,
  loading,
  totalCount,
  currentPage,
  onPageChange,
  topicsPerPage,
  onTopicSelect,
  includePagination = true
}) => {
  if (loading) {
    return (
      <div className={styles.topicsLoadingContainer}>
        <LoadingSpinner text="Loading topics..." />
      </div>
    );
  }

  // Calculate max count for scaling
  const maxCount = Math.max(...topics.map(t => t.excerpt_count));

  // Calculate tag size similar to ExcerptTags
  const getTagSize = (count) => {
    return Math.floor((count / maxCount) * 2);
  };

  // Sort topics by excerpt count (descending) and then alphabetically
  const sortedTopics = topics 
    ? [...topics]
        .filter(topic => topic && topic.name)
        .sort((a, b) => {
          // First sort by excerpt_count (descending)
          const countDiff = b.excerpt_count - a.excerpt_count;
          // If counts are equal, sort alphabetically
          return countDiff !== 0 ? countDiff : a.name.localeCompare(b.name);
        })
    : [];

  return (
    <div className={styles.topicsSection}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.topicsWrapper}>
        {sortedTopics.map((topic) => {
          const bgColor = getTagColor(topic.name);
          const textColor = getContrastColor(bgColor);
          const tagSize = getTagSize(topic.excerpt_count);
          
          return (
            <span
              key={topic.tags_uuid}
              className={styles.topic}
              onClick={() => onTopicSelect?.(topic.name, topic, true)}
              style={{
                backgroundColor: bgColor,
                color: textColor,
                padding: `${4}px ${8}px`,
                fontSize: `${0.8}em`,
                fontWeight: tagSize > 2 ? 'bold' : 'normal',
              }}
            >
              {topic.name}
              <span className={styles.topicCount}>
                {topic.excerpt_count}
              </span>
            </span>
          );
        })}
      </div>
      {includePagination && totalCount > topicsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalCount}
          itemsPerPage={topicsPerPage}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default TopicsList;
