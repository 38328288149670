import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import Tags from './Tags';
import styles from '../styles/ExcerptsTable.module.css';

const ExcerptsTable = ({ excerpts, onSelectExcerpt, onTagSelect }) => {
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (excerptUuid) => {
    setImageErrors(prev => ({
      ...prev,
      [excerptUuid]: true
    }));
  };

  const handlePersonSelect = (personsUuid) => {
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  const handleShowSelect = (showsUuid) => {
    window.open(`/shows/${showsUuid}`, '_blank');
  };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listWrapper}>
        <ul className={styles.list}>
          {excerpts.map((excerpt, index) => {
            const imageUrl = imageErrors[excerpt.excerpt.excerpts_uuid] || 
              !excerpt.episode?.episode_image
              ? '/placeholder.webp'
              : excerpt.episode.episode_image;

            return (
              <li key={excerpt.excerpt.excerpts_uuid} className={styles.listItem}>
                <div className={styles.mobileStack}>
                  <div className={styles.mainRow}>
                    <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                      <img 
                        src={imageUrl}
                        alt={excerpt.excerpt.title} 
                        className={styles.thumbnailImage}
                        onError={() => handleImageError(excerpt.excerpt.excerpts_uuid)}
                      />
                      <div className={styles.playOverlay}>
                        <FaPlay className={styles.playIcon} />
                      </div>
                    </div>
                    <div className={styles.contentContainer}>
                      <span 
                        className={styles.title}
                        onClick={() => onSelectExcerpt(excerpt, index)}
                      >
                        {excerpt.excerpt.title}
                      </span>
                      <div className={styles.metaInfo}>
                        {excerpt.guests.length > 0 && (
                          <div className={styles.metaRow}>
                            <span 
                              className={styles.showLink}
                              onClick={() => handlePersonSelect(excerpt.guests[0].persons_uuid)}
                            >
                              {excerpt.guests[0].name}
                            </span>
                          </div>
                        )}
                        <div className={styles.metaRow}>
                          <span 
                            className={styles.guestLink}
                            onClick={() => handleShowSelect(excerpt.show.shows_uuid)}
                          >
                            {excerpt.show.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.tagsRow}>
                    <Tags 
                      tags={excerpt.excerpt.tags} 
                      onTagSelect={onTagSelect}
                    />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ExcerptsTable;
