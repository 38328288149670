import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllShows } from '../store/allShowsSlice';
import { fetchEpisodes } from '../store/episodesSlice';
import { fetchTrendingTopics } from '../store/trendingTopicsSlice';
import { fetchFeed } from '../store/feedSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import styles from '../styles/BrowsePage.module.css';
import PageHelmet from './PageHelmet';
import { FaChartLine, FaChevronRight } from 'react-icons/fa';
import { format } from 'timeago.js';
import { getRandomTags } from '../api/api';
import { getTagColor, getContrastColor } from '../utils/utils';
import { createPortal } from 'react-dom';
import ExcerptPlayer from './ExcerptPlayer';

const BrowsePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { data: showsData, loading: showsLoading, error: showsError, hasMore } = useSelector((state) => state.shows);
    const { data: episodesData, loading: episodesLoading, error: episodesError } = useSelector((state) => state.episodes);
    const { data: trendingData, loading: trendingLoading, error: trendingError } = useSelector((state) => state.trending);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
    const { data: feedData, loading: feedLoading, error: feedError } = useSelector((state) => state.feed);

    const [recentEpisodes, setRecentEpisodes] = useState([]);
    const trendingListRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagsLoading, setTagsLoading] = useState(true);
    const [tagsError, setTagsError] = useState(null);
    const [recentDiscussions, setRecentDiscussions] = useState([]);
    const [selectedExcerpt, setSelectedExcerpt] = useState(null);
    const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
    const [overlayRoot, setOverlayRoot] = useState(null);
    const [discussionImageErrors, setDiscussionImageErrors] = useState({});
    const [episodeImageErrors, setEpisodeImageErrors] = useState({});
    const [showImageErrors, setShowImageErrors] = useState({});

    const truncateTitle = (title, maxLength = 50) => {
        if (!title) return '';
        if (title.length <= maxLength) return title;
        return title.slice(0, maxLength - 3) + '...';
    };

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                dispatch(fetchAllShows({ page: 1, limit: 20 }));
                dispatch(fetchEpisodes());
                dispatch(fetchTrendingTopics({ limit: 10 }));
                dispatch(fetchFeed(null));
            } else {
                localStorage.setItem('redirectAfterAuth', location.pathname);
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [dispatch, isAuthenticated, authLoading, navigate, location]);

    useEffect(() => {
        if (episodesData && episodesData.episodes) {
            const sorted = [...episodesData.episodes]
                .sort((a, b) => new Date(b.published_date) - new Date(a.published_date))
                .slice(0, 10);
            setRecentEpisodes(sorted);
        }
    }, [episodesData]);

    useEffect(() => {
        if (feedData?.excerpts) {
            const sorted = [...feedData.excerpts]
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .slice(0, 10);
            setRecentDiscussions(sorted);
        }
    }, [feedData]);

    useEffect(() => {
        const list = trendingListRef.current;
        if (!list || !trendingData?.topics?.length) return;

        let scrollInterval;
        let scrollPosition = list.scrollLeft;
        const scrollSpeed = 1;
        const totalWidth = list.scrollWidth - list.clientWidth;
        let isWaiting = false;

        const scroll = () => {
            if (isPaused || isWaiting) {
                scrollPosition = list.scrollLeft;
                return;
            }

            scrollPosition += scrollSpeed;
            
            if (scrollPosition >= totalWidth) {
                isWaiting = true;
                setTimeout(() => {
                    scrollPosition = 0;
                    list.scrollLeft = 0;
                    isWaiting = false;
                }, 3000);
                return;
            }
            
            list.scrollLeft = scrollPosition;
        };

        scrollInterval = setInterval(scroll, 30);

        // Add touch event listeners with delayed resume
        const handleTouchStart = () => setIsPaused(true);
        const handleTouchEnd = () => {
            // Wait a bit before resuming auto-scroll
            setTimeout(() => {
                scrollPosition = list.scrollLeft;
                setIsPaused(false);
            }, 3000);
        };

        list.addEventListener('touchstart', handleTouchStart);
        list.addEventListener('touchend', handleTouchEnd);

        return () => {
            clearInterval(scrollInterval);
            list.removeEventListener('touchstart', handleTouchStart);
            list.removeEventListener('touchend', handleTouchEnd);
        };
    }, [trendingData, isPaused]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await getRandomTags({ limit: 30 });
                setTags(response.tags || []);
            } catch (error) {
                setTagsError(error.message);
            } finally {
                setTagsLoading(false);
            }
        };

        if (isAuthenticated && !authLoading) {
            fetchTags();
        }
    }, [isAuthenticated, authLoading]);

    useEffect(() => {
        setOverlayRoot(document.getElementById('overlay-root'));
    }, []);

    const loadMoreShows = () => {
        if (hasMore && !showsLoading) {
            dispatch(fetchAllShows({ page: showsData.pagination.page + 1, limit: 20 }));
        }
    };

    const handleShowClick = (event, showUuid) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        event.preventDefault();
        navigate(`/shows/${showUuid}`);
    };

    const handleEpisodeClick = (event, episodeUuid) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        event.preventDefault();
        navigate(`/episodes/${episodeUuid}`);
    };

    const handleTrendingTopicClick = (event, topicUuid) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        event.preventDefault();
        navigate(`/trending/${topicUuid}`);
    };

    const handleSelectExcerpt = (excerpt, index) => {
        setSelectedExcerpt(excerpt);
        setCurrentExcerptIndex(index);
    };

    const handleCloseExcerpt = () => {
        setSelectedExcerpt(null);
        setCurrentExcerptIndex(null);
    };

    const goToNextExcerpt = () => {
        if (recentDiscussions && currentExcerptIndex < recentDiscussions.length - 1) {
            const nextIndex = currentExcerptIndex + 1;
            setCurrentExcerptIndex(nextIndex);
            setSelectedExcerpt(recentDiscussions[nextIndex]);
        }
    };

    const goToPreviousExcerpt = () => {
        if (currentExcerptIndex > 0) {
            const prevIndex = currentExcerptIndex - 1;
            setCurrentExcerptIndex(prevIndex);
            setSelectedExcerpt(recentDiscussions[prevIndex]);
        }
    };

    const handleDiscussionImageError = (excerptUuid) => {
        setDiscussionImageErrors(prev => ({
            ...prev,
            [excerptUuid]: true
        }));
    };

    const handleEpisodeImageError = (episodeUuid) => {
        setEpisodeImageErrors(prev => ({
            ...prev,
            [episodeUuid]: true
        }));
    };

    const handleShowImageError = (showUuid) => {
        setShowImageErrors(prev => ({
            ...prev,
            [showUuid]: true
        }));
    };

    const renderOverlay = () => {
        if (!selectedExcerpt || !overlayRoot) return null;

        return createPortal(
            <div 
                className={styles.overlay} 
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        handleCloseExcerpt();
                    }
                }}
            >
                <div className={styles.overlayContent}>
                    <ExcerptPlayer
                        excerpt={selectedExcerpt}
                        startPlaying={true}
                        onAudioEnded={goToNextExcerpt}
                        onNextExcerpt={goToNextExcerpt}
                        onPreviousExcerpt={goToPreviousExcerpt}
                    />
                    <button className={styles.closeButton} onClick={handleCloseExcerpt}>
                        ×
                    </button>
                </div>
            </div>,
            overlayRoot
        );
    };

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (showsError || episodesError || trendingError) {
        return <div>Error: {showsError || episodesError || trendingError}</div>;
    }

    return (
        <main className={styles.mainContainer}>
            <PageHelmet title="Browse" />

            {/* Trending Topics Section */}
            <section className={styles.section}>
                <div className={styles.browseContainer}>
                    <h2 
                        className={`${styles.browseHeader} ${styles.clickableHeader}`}
                        onClick={() => navigate('/trending')}
                        style={{ cursor: 'pointer' }}
                    >
                        Trending on 𝕏
                        <FaChevronRight className={styles.headerChevron} />
                    </h2>
                    {
                        trendingLoading && !trendingData ? (
                            <div className={styles.browseLoadingContainer}>
                                <LoadingSpinner text="Loading trending topics..." />
                            </div>
                        ) : (
                            <div 
                                ref={trendingListRef}
                                className={`${styles.browseList} ${styles.trendingList}`}
                                onMouseEnter={() => setIsPaused(true)}
                                onMouseLeave={() => setIsPaused(false)}
                            >
                                {
                                    trendingData?.topics ? trendingData.topics.map((topic) => (
                                        <a 
                                            href={`/trending/${topic.trending_topics_uuid}`}
                                            key={topic.trending_topics_uuid} 
                                            className={styles.trendingItem}
                                            onClick={(e) => handleTrendingTopicClick(e, topic.trending_topics_uuid)}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <div className={styles.trendingContent}>
                                                <div className={styles.trendingTitleContainer}>
                                                    <FaChartLine className={styles.trendingIcon} />
                                                    <h3 className={styles.trendingTitle}>
                                                        {topic.title || ''}
                                                    </h3>
                                                </div>
                                                <time className={styles.trendingTime}>
                                                    {format(topic.created_at)}
                                                </time>
                                            </div>
                                        </a>
                                    )) : null
                                }
                            </div>
                        )
                    }
                </div>
            </section>

            {/* Discussions Section */}
            <section className={styles.section}>
                <div className={styles.browseContainer}>
                    <h2 
                        className={`${styles.browseHeader} ${styles.clickableHeader}`}
                        onClick={() => navigate('/feed')}
                        style={{ cursor: 'pointer' }}
                    >
                        Discussions
                        <FaChevronRight className={styles.headerChevron} />
                    </h2>
                    {
                        feedLoading && !feedData ? (
                            <div className={styles.browseLoadingContainer}>
                                <LoadingSpinner text="Loading discussions..." />
                            </div>
                        ) : (
                            <div className={styles.browseList}>
                                {
                                    recentDiscussions?.map((excerpt, index) => {
                                        const imageUrl = discussionImageErrors[excerpt.excerpt.excerpts_uuid] || 
                                            !(excerpt.episode?.episode_image || excerpt.show?.shows_image)
                                            ? '/placeholder.webp'
                                            : (excerpt.episode?.episode_image || excerpt.show?.shows_image);

                                        return (
                                            <div 
                                                key={excerpt.excerpt.excerpts_uuid} 
                                                className={styles.browseItem}
                                                onClick={(e) => {
                                                    if (e.ctrlKey || e.metaKey || e.button === 1) return;
                                                    e.preventDefault();
                                                    handleSelectExcerpt(excerpt, index);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img 
                                                    src={imageUrl}
                                                    alt={excerpt.excerpt.title || ''} 
                                                    className={styles.browseImage}
                                                    onError={() => handleDiscussionImageError(excerpt.excerpt.excerpts_uuid)}
                                                />
                                                <h3 className={styles.browseTitle}>
                                                    {truncateTitle(excerpt.excerpt.title)}
                                                </h3>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </section>

            {/* Topics Section */}
            <section className={styles.section}>
                <div className={styles.browseContainer}>
                    <h2 
                        className={`${styles.browseHeader} ${styles.clickableHeader}`}
                        onClick={() => navigate('/explore')}
                        style={{ cursor: 'pointer' }}
                    >
                        Explore by Topic
                        <FaChevronRight className={styles.headerChevron} />
                    </h2>
                    {tagsLoading ? (
                        <div className={styles.browseLoadingContainer}>
                            <LoadingSpinner text="Loading topics..." />
                        </div>
                    ) : tagsError ? (
                        <div>Error: {tagsError}</div>
                    ) : (
                        <div className={styles.topicsWrapper}>
                            {tags.map((tag) => (
                                <span
                                    key={tag.tags_uuid}
                                    className={styles.topic}
                                    onClick={() => window.open(`/explore?tags_uuid=${tag.tags_uuid}`, '_blank')}
                                    style={{
                                        backgroundColor: getTagColor(tag.name),
                                        color: getContrastColor(getTagColor(tag.name)),
                                        padding: '8px 16px',
                                    }}
                                >
                                    {tag.name}
                                    <span className={styles.topicCount}>
                                        {tag.excerpt_count}
                                    </span>
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </section>

            {/* Recent Episodes Section */}
            <section className={styles.section}>
                <div className={styles.browseContainer}>
                    <h2 className={styles.browseHeader}>Recent Episodes</h2>
                    {
                        episodesLoading && !episodesData ? (
                            <div className={styles.browseLoadingContainer}>
                                <LoadingSpinner text="Loading episodes..." />
                            </div>
                        ) : (
                            <div className={styles.browseList}>
                                {
                                    recentEpisodes?.map((episode) => {
                                        const imageUrl = episodeImageErrors[episode.episodes_uuid] || 
                                            !episode.episode_image
                                            ? '/placeholder.webp'
                                            : episode.episode_image;

                                        return (
                                            <a 
                                                href={`/episodes/${episode.episodes_uuid}`}
                                                key={episode.episodes_uuid} 
                                                className={styles.browseItem}
                                                onClick={(e) => handleEpisodeClick(e, episode.episodes_uuid)}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <img 
                                                    src={imageUrl}
                                                    alt={episode.title || ''} 
                                                    className={styles.browseImage}
                                                    onError={() => handleEpisodeImageError(episode.episodes_uuid)}
                                                />
                                                <h3 className={styles.browseTitle}>
                                                    {truncateTitle(episode.title)}
                                                </h3>
                                            </a>
                                        );
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </section>

            {/* Shows Section */}
            <section className={styles.section}>
                <div className={styles.browseContainer}>
                    <h2 className={styles.browseHeader}>Shows</h2>
                    {
                        showsLoading ? (
                            <div className={styles.browseLoadingContainer}>
                                <LoadingSpinner text="Loading shows..." />
                            </div>
                        ) : (
                            <div className={styles.browseList}>
                                {
                                    showsData?.shows ? showsData.shows.map((show) => {
                                        const imageUrl = showImageErrors[show.shows_uuid] || 
                                            !show.shows_image
                                            ? '/placeholder.webp'
                                            : show.shows_image;

                                        return (
                                            <a 
                                                href={`/shows/${show.shows_uuid}`}
                                                key={show.shows_uuid} 
                                                className={styles.browseItem}
                                                onClick={(e) => handleShowClick(e, show.shows_uuid)}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <img 
                                                    src={imageUrl}
                                                    alt={show.title || ''} 
                                                    className={styles.browseImage}
                                                    onError={() => handleShowImageError(show.shows_uuid)}
                                                />
                                                <h3 className={styles.browseTitle}>
                                                    {truncateTitle(show.title)}
                                                </h3>
                                            </a>
                                        );
                                    }) : null
                                }
                                {!showsLoading && hasMore && (
                                    <div 
                                        className={`${styles.loadMoreItem}`}
                                        onClick={loadMoreShows}
                                    >
                                        <div className={styles.loadMoreContent}>
                                            <span className={styles.loadMoreIcon}>+</span>
                                            <span className={styles.loadMoreText}>Load More</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
            </section>

            {renderOverlay()}
        </main>
    );
};

export default BrowsePage;
