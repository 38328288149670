import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import Person from './Person';
import styles from '../styles/EpisodesList.module.css';
import { FaPlay, FaCalendarAlt } from 'react-icons/fa';
import Pagination from './Pagination';

const EpisodeList = ({ 
  episodes, 
  title = "Episodes",
  loading = false,
  totalCount = 0,
  currentPage = 1,
  onPageChange,
  episodesPerPage = 10,
  episodeType,
  onTypeToggle,
  displayShowName = false,
  displayGuests = false
}) => {
  const navigate = useNavigate();
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (episodeUuid) => {
    setImageErrors(prev => ({
      ...prev,
      [episodeUuid]: true
    }));
  };

  if (!episodes && !loading) {
    return null;
  }

  const totalPages = Math.ceil(totalCount / episodesPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const handleEpisodeClick = (e, episodeUuid) => {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      navigate(`/episodes/${episodeUuid}`);
    }
  };

  const handlePersonClick = (e, personsUuid) => {
    e.preventDefault();
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  return (
    <div className={`${styles.container} ${loading ? styles.loading : ''}`}>
      {loading && (
        <div className={styles.loadingWrapper}>
          <LoadingSpinner text="Loading episodes..." />
        </div>
      )}
      <div className={styles.header}>
        <h2 className={styles.title}>{title} ({totalCount})</h2>
        {!loading && (
          <button className={styles.button} onClick={onTypeToggle}>
            {episodeType === 'all' ? 'Ingested Only' : 'Show All'}
          </button>
        )}
      </div>

      <div className={styles.wrapper}>
        {totalCount === 0 ? (
          <p className={styles.title}>
            {episodeType === 'all' ? 'No episodes found' : 'No ingested episodes found'}
          </p>
        ) : (
          <ul className={styles.list}>
            {episodes.map((episodeInfo) => {
              const episodeUuid = episodeInfo.episode.episodes_uuid;
              const imageUrl = imageErrors[episodeUuid] || !episodeInfo.episode.episode_image
                ? '/placeholder.webp'
                : episodeInfo.episode.episode_image;

              return (
                <li key={episodeUuid} className={styles.listItem}>
                  <a 
                    href={`/episodes/${episodeUuid}`}
                    onClick={(e) => handleEpisodeClick(e, episodeUuid)}
                    className={styles.episodeLink}
                  >
                    <div className={styles.imageContainer}>
                      <img 
                        src={imageUrl}
                        alt={episodeInfo.episode.title}
                        className={styles.image}
                        onError={() => handleImageError(episodeUuid)}
                      />
                      <div className={styles.playOverlay}>
                        <FaPlay className={styles.playIcon} />
                      </div>
                    </div>
                    <div className={styles.content}>
                      <h3 className={styles.episodeTitle}>{episodeInfo.episode.title}</h3>
                      {displayShowName && episodeInfo.show?.title && (
                        <p className={styles.showName}>{episodeInfo.show.title}</p>
                      )}
                      {displayGuests && episodeInfo.guests && episodeInfo.guests.length > 0 && (
                        <div className={styles.guestNames}>
                          {episodeInfo.guests.map((guest, index) => (
                            <React.Fragment key={guest.persons_uuid}>
                              <a
                                href={`/persons/${guest.persons_uuid}`}
                                onClick={(e) => handlePersonClick(e, guest.persons_uuid)}
                                className={styles.guestLink}
                              >
                                {guest.name}
                              </a>
                              {index < episodeInfo.guests.length - 1 && <span className={styles.guestSeparator}>, </span>}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      <p className={styles.date}>
                        <FaCalendarAlt className={styles.calendarIcon} />
                        {new Date(episodeInfo.episode.published_date).toLocaleDateString()}
                      </p>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {totalPages > 1 && (
        <div className={styles.paginationWrapper}>
          <Pagination 
            currentPage={currentPage}
            totalItems={totalCount}
            itemsPerPage={episodesPerPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default EpisodeList;
