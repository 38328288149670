import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonByUUID, fetchPersonEpisodes, fetchPersonTopics } from '../store/personInfoSlice';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import { FaShareSquare, FaPlay } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/PersonPage.module.css';
import EpisodesList from './EpisodesList';
import ShowsList from './ShowsList';
import PageHelmet from './PageHelmet';
import PersonImage from './PersonImage';
import TopicsList from './TopicsList';

const PersonPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const personState = useSelector(state => state.personInfo);
  const { data, loading, error, episodes, episodesLoading, topics, topicsLoading } = personState;
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [personData, setPersonData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [episodesPerPage] = useState(10);
  const [episodeType, setEpisodeType] = useState('ingested');
  const [topicsCurrentPage, setTopicsCurrentPage] = useState(1);
  const [topicsPerPage] = useState(120);

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated) {
        if (uuid) {
          setPersonData(null);
          dispatch(fetchPersonByUUID(uuid));
          fetchEpisodePage(1);
          fetchTopicsPage(1);
        }
      } else {
        localStorage.setItem('redirectAfterAuth', location.pathname);
        navigate('/auth?from=' + encodeURIComponent(location.pathname));
      }
    }
  }, [uuid, dispatch, isAuthenticated, authLoading, navigate, location]);

  useEffect(() => {
    if (!loading && !error && data !== null) {
      const sortedEpisodes = [...data.episodes].sort((a, b) => 
        new Date(b.published_date) - new Date(a.published_date)
      );
      setPersonData({
        ...data,
        episodes: sortedEpisodes,
      });
    }
  }, [data, loading, error]);

  const handleShareClick = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Person Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
  };

  const handlePlayFeedClick = () => {
    if (personData && personData.person) {
      navigate(`/feed?persons_uuid=${personData.person.persons_uuid}`);
    }
  };

  const fetchEpisodePage = (page) => {
    dispatch(fetchPersonEpisodes({ 
      uuid,
      params: {
        episodeType,
        numEpisodes: episodesPerPage,
        pageNumber: page
      }
    }));
  };

  const fetchTopicsPage = (page) => {
    dispatch(fetchPersonTopics({ 
      uuid,
      params: {
        numTopics: topicsPerPage,
        pageNumber: page
      }
    }));
  };

  const handleTypeToggle = () => {
    const newType = episodeType === 'all' ? 'ingested' : 'all';
    setEpisodeType(newType);
    setCurrentPage(1);
    dispatch(fetchPersonEpisodes({ 
      uuid,
      params: {
        episodeType: newType,
        numEpisodes: episodesPerPage,
        pageNumber: 1
      }
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchEpisodePage(newPage);
  };

  const handleTopicsPageChange = (newPage) => {
    setTopicsCurrentPage(newPage);
    fetchTopicsPage(newPage);
  };

  const handleTagSelect = (tagName, tag) => {
    window.open(`/explore?persons_uuid=${personData.person?.persons_uuid}&tags_uuid=${tag.tags_uuid}`, '_blank');
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading person..." />;
  }

  if (personData == null) {
    return <LoadingSpinner text="Processing person data..." />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.personContainer}>
      <PageHelmet title={personData.person.name} />
      <ToastContainer
        toastClassName={styles.customToast}
        progressClassName={styles.customProgress}
      />
      <main className={styles.mainContent}>
        <div className={styles.personHeader}>
          <div className={styles.personImageContainer}>
            <PersonImage 
              src={personData.person.public_image_url}
              alt={personData.person.name}
              size={200}
            />
          </div>
          <div className={styles.personInfo}>
            <h1 className={styles.personName}>
              {personData.person.name}
            </h1>
            {personData.person.bio && (
              <p className={styles.personBio}>{personData.person.bio}</p>
            )}
            <div className={styles.buttonSection}>
              <button 
                className={styles.playFeedButton}
                onClick={handlePlayFeedClick}
              >
                <FaPlay className={styles.buttonIcon} />
                Play Feed
              </button>
              <button 
                className={styles.shareButton}
                onClick={handleShareClick}
              >
                <FaShareSquare className={styles.buttonIcon} />
                Share
              </button>
            </div>
          </div>
        </div>
        
        {topics && topics.totalCount > 0 && (
          <div className={styles.sectionContainer}>
            <TopicsList
              title="Popular Topics"
              topics={topics.items}
              loading={topicsLoading}
              totalCount={topics.totalCount}
              currentPage={topicsCurrentPage}
              onPageChange={handleTopicsPageChange}
              topicsPerPage={topicsPerPage}
              includePagination={true}
              onTopicSelect={handleTagSelect}
            />
          </div>
        )}

        {personData.shows && personData.shows.length > 0 && (
          <div className={styles.showsSection}>
            <ShowsList 
              shows={personData.shows} 
              title="Hosts" 
            />
          </div>
        )}

        <div className={styles.sectionContainer}>
          <EpisodesList 
            title={episodeType === 'all' ? 'Episodes' : 'Episodes'}
            episodes={episodes.items} 
            loading={episodesLoading}
            totalCount={episodes.totalCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            episodesPerPage={episodesPerPage}
            episodeType={episodeType}
            onTypeToggle={handleTypeToggle}
            displayShowName={true}
          />
        </div>
      </main>
    </div>
  );
};

export default PersonPage;
