import React, { useState } from 'react';
import { FaPlay, FaStar } from 'react-icons/fa';
import styles from '../styles/RelatedExcerptsTable.module.css';

const RelatedExcerptsTable = ({ excerpts, onSelectExcerpt }) => {
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (excerptUuid) => {
    setImageErrors(prev => ({
      ...prev,
      [excerptUuid]: true
    }));
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <ul className={styles.excerptsList}>
          {excerpts.map((excerpt, index) => {
            const context = excerpt.relationship?.context;
            const excerptUuid = excerpt.excerpt.excerpts_uuid;
            const imageUrl = imageErrors[excerptUuid] || !excerpt.episode?.episode_image
              ? '/placeholder.webp'
              : excerpt.episode.episode_image;
            const isSpecificallyRelevant = excerpt.relationship?.relation === 'is_specifically_relevant';
            
            return (
              <li key={excerptUuid} className={styles.excerptItem}>
                <div className={styles.excerptContent}>
                  <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                    <img 
                      src={imageUrl}
                      alt={excerpt.excerpt.title} 
                      className={styles.thumbnailImage}
                      onError={() => handleImageError(excerptUuid)}
                    />
                    <div className={styles.playOverlay}>
                      <FaPlay className={styles.playIcon} />
                    </div>
                  </div>
                  <div className={styles.textContent}>
                    <div className={styles.titleContainer}>
                      <span 
                        className={styles.title}
                        onClick={() => onSelectExcerpt(excerpt, index)}
                      >
                        {excerpt.excerpt.title}
                      </span>
                      {isSpecificallyRelevant && (
                        <span title="Specifically relevant">
                          <FaStar className={styles.starIcon} />
                        </span>
                      )}
                    </div>
                    <div className={styles.contextContent}>
                      {context || 'No context available'}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RelatedExcerptsTable;
