import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowByUUID, fetchShowEpisodes, clearShowData, fetchShowTopics } from '../store/showInfoSlice';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import Person from './Person';
import { FaPlay, FaGlobe, FaShareSquare, FaFilter, FaChartLine, FaChevronRight, FaSearch } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/ShowPage.module.css';
import EpisodesList from './EpisodesList';
import PageHelmet from './PageHelmet';
import TopicsList from './TopicsList';
import IngestionState from './IngestionState';
import PersonFilterModal from './PersonFilterModal';
import { getAllPersons } from '../api/api';
import { fetchTrendingTopics } from '../store/trendingTopicsSlice';
import { format } from 'timeago.js';

const ShowPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showState = useSelector(state => state.showInfo) || {};
  const { data, episodes, loading, episodesLoading, error, topics, topicsLoading } = showState;
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [showData, setShowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [episodesPerPage, setEpisodesPerPage] = useState(10);
  const [episodeType, setEpisodeType] = useState('ingested');
  const [topicsCurrentPage, setTopicsCurrentPage] = useState(1);
  const [topicsPerPage, setTopicsPerPage] = useState(120);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [guests, setGuests] = useState([]);
  const [guestsLoading, setGuestsLoading] = useState(false);
  const [guestsError, setGuestsError] = useState(null);
  const [guestsCount, setGuestsCount] = useState(0);
  const { data: trendingData, loading: trendingLoading, error: trendingError } = useSelector((state) => state.trending);
  const trendingListRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [showImageError, setShowImageError] = useState(false);

  const loadGuests = async () => {
    try {
      setGuestsLoading(true);
      setGuestsError(null);
      const response = await getAllPersons({
        page: 1,
        limit: 20,
        shows_uuids: [uuid],
        is_guest: true
      });
      
      setGuests(response?.persons || []);
      setGuestsCount(response?.total_count || 0);
    } catch (err) {
      setGuestsError(err.message || 'Failed to load guests');
      setGuests([]);
    } finally {
      setGuestsLoading(false);
    }
  };

  useEffect(() => {
    if (!authLoading && isAuthenticated && uuid) {
      setShowData(null);
      dispatch(fetchShowByUUID(uuid));
      fetchEpisodePage(1);
      fetchTopicsPage(1);
      loadGuests();
    }

    return () => {
      setShowData(null);
      dispatch(clearShowData());
    };
  }, [uuid, isAuthenticated, authLoading]);

  useEffect(() => {
    if (!loading && !error && data !== null) {
      const sortedHosts = [...data.hosts].sort((a, b) => 
        a.last_name.localeCompare(b.last_name)
      );
      // Randomly shuffle the guests array using Fisher-Yates algorithm
      const shuffledGuests = [...data.guests];
      for (let i = shuffledGuests.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledGuests[i], shuffledGuests[j]] = [shuffledGuests[j], shuffledGuests[i]];
      }
      setShowData({
        ...data,
        guests: shuffledGuests,
        hosts: sortedHosts,
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (uuid && isAuthenticated) {
      dispatch(fetchTrendingTopics({ shows_uuids: [uuid], limit: 10 }))
        .unwrap()
        .catch(error => {
          console.error('Failed to fetch trending topics:', error);
          // Optionally show a toast or handle error
        });
    }
  }, [dispatch, uuid, isAuthenticated]);

  useEffect(() => {
    const list = trendingListRef.current;
    if (!list || !trendingData?.topics?.length) return;

    let scrollInterval;
    let scrollPosition = list.scrollLeft;
    const scrollSpeed = 1;
    const totalWidth = list.scrollWidth - list.clientWidth;
    let isWaiting = false;

    const scroll = () => {
      if (isPaused || isWaiting) {
        scrollPosition = list.scrollLeft;
        return;
      }

      scrollPosition += scrollSpeed;
      
      if (scrollPosition >= totalWidth) {
        isWaiting = true;
        setTimeout(() => {
          scrollPosition = 0;
          list.scrollLeft = 0;
          isWaiting = false;
        }, 3000);
        return;
      }
      
      list.scrollLeft = scrollPosition;
    };

    scrollInterval = setInterval(scroll, 30);

    const handleTouchStart = () => setIsPaused(true);
    const handleTouchEnd = () => {
      setTimeout(() => {
        scrollPosition = list.scrollLeft;
        setIsPaused(false);
      }, 3000);
    };

    list.addEventListener('touchstart', handleTouchStart);
    list.addEventListener('touchend', handleTouchEnd);

    return () => {
      clearInterval(scrollInterval);
      list.removeEventListener('touchstart', handleTouchStart);
      list.removeEventListener('touchend', handleTouchEnd);
    };
  }, [trendingData, isPaused]);

  const handlePlayFeed = () => {
    if (showData && showData.show) {
      navigate(`/feed?shows_uuid=${showData.show.shows_uuid}`);
    }
  };

  const handleShareClick = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Show Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
  };

  const handleSearchClick = () => {
    if (showData && showData.show) {
      navigate(`/search?shows_uuids=${showData.show.shows_uuid}`);
    }
  };

  const fetchEpisodePage = (page) => {
    dispatch(fetchShowEpisodes({ 
      uuid,
      params: {
        episodeType,
        numEpisodes: episodesPerPage,
        pageNumber: page
      }
    }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchEpisodePage(newPage);
  };

  const handleTypeToggle = () => {
    const newType = episodeType === 'all' ? 'ingested' : 'all';
    setEpisodeType(newType);
    setCurrentPage(1);
    dispatch(fetchShowEpisodes({ 
      uuid,
      params: {
        episodeType: newType,
        numEpisodes: episodesPerPage,
        pageNumber: 1
      }
    }));
  };

  const fetchTopicsPage = (page) => {
    dispatch(fetchShowTopics({ 
      uuid,
      params: {
        numTopics: topicsPerPage,
        pageNumber: page
      }
    }));
  };

  const handleTopicsPageChange = (newPage) => {
    setTopicsCurrentPage(newPage);
    fetchTopicsPage(newPage);
  };

  const handleTagSelect = (tagName, tag) => {
    window.open(`/explore?shows_uuid=${showData.show?.shows_uuid}&tags_uuid=${tag.tags_uuid}`, '_blank');
  };

  const handleGuestSelect = (person) => {
    setIsGuestModalOpen(false);
    navigate(`/persons/${person.persons_uuid}`);
  };

  const handleTrendingTopicClick = (event, topicUuid) => {
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      return;
    }
    event.preventDefault();
    navigate(`/trending/${topicUuid}`);
  };

  const handleShowImageError = () => {
    setShowImageError(true);
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading show..." />;
  }

  if (showData == null) {
    return <LoadingSpinner text="Processing show..." />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.showContainer}>
      {showData && (
        <PageHelmet title={showData.show.title} />
      )}
      <ToastContainer
        toastClassName={styles.customToast}
        progressClassName={styles.customProgress}
      />
      <main className={styles.mainContent}>
        <div className={styles.showHeader}>
          <div className={styles.showImageContainer}>
            {showData && (
              <img 
                className={styles.showImage} 
                src={showImageError || !showData.show.shows_image ? '/placeholder.webp' : showData.show.shows_image}
                alt={showData.show.title || 'Show image'} 
                onError={handleShowImageError}
              />
            )}
          </div>
          <div className={styles.showInfo}>
            <h1 className={styles.showTitle}>{showData.show.title}</h1>
            <p className={styles.showDescription}>{showData.show.description}</p>
            {showData.hosts && showData.hosts.length > 0 && (
              <div className={styles.hostsSection}>
                <ul className={styles.hostsList}>
                  {showData.hosts.map((host) => (
                    <li key={host.persons_uuid} className={styles.hostItem}>
                      <Person person={host} size={40} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {showData.show && (
              <IngestionState 
                totalEpisodes={showData.show.total_episodes_count || 0}
                ingestedEpisodes={showData.show.ingested_episodes_count || 0}
              />
            )}
            <div className={styles.buttonSection}>
              <button 
                className={styles.playFeedButton}
                onClick={handlePlayFeed}
              >
                <FaPlay className={styles.buttonIcon} />
                Feed
              </button>
              <button 
                className={styles.searchButton}
                onClick={handleSearchClick}
              >
                <FaSearch className={styles.buttonIcon} />
                Search
              </button>
              {showData.show.url && (
                <button 
                  className={styles.showSiteButton}
                  onClick={() => window.open(showData.show.url, '_blank', 'noopener,noreferrer')}
                >
                  <FaGlobe className={styles.buttonIcon} />
                  Site
                </button>
              )}
            </div>
          </div>
        </div>

        <section className={styles.section}>
          <div className={styles.browseContainer}>
            <h2 
              className={`${styles.browseHeader} ${styles.clickableHeader}`}
              onClick={() => navigate('/trending')}
              style={{ cursor: 'pointer' }}
            >
              Trending on 𝕏
              <FaChevronRight className={styles.headerChevron} />
            </h2>
            {
              trendingLoading && !trendingData ? (
                <div className={styles.browseLoadingContainer}>
                  <LoadingSpinner text="Loading trending topics..." />
                </div>
              ) : (
                <div 
                  ref={trendingListRef}
                  className={`${styles.browseList} ${styles.trendingList}`}
                  onMouseEnter={() => setIsPaused(true)}
                  onMouseLeave={() => setIsPaused(false)}
                >
                  {
                    trendingData?.topics ? trendingData.topics.map((topic) => (
                      <a 
                        href={`/trending/${topic.trending_topics_uuid}`}
                        key={topic.trending_topics_uuid} 
                        className={styles.trendingItem}
                        onClick={(e) => handleTrendingTopicClick(e, topic.trending_topics_uuid)}
                        style={{ textDecoration: 'none' }}
                      >
                        <div className={styles.trendingContent}>
                          <div className={styles.trendingTitleContainer}>
                            <FaChartLine className={styles.trendingIcon} />
                            <h3 className={styles.trendingTitle}>
                              {topic.title || ''}
                            </h3>
                          </div>
                          <time className={styles.trendingTime}>
                            {format(topic.created_at)}
                          </time>
                        </div>
                      </a>
                    )) : null
                  }
                </div>
              )
            }
          </div>
        </section>
        
        {topics && topics.totalCount > 0 && (
          <div className={styles.sectionContainer}>
            <TopicsList
              title={
                <h2 
                  className={`${styles.sectionTitle} ${styles.clickableHeader}`}
                  onClick={() => navigate(`/explore?shows_uuid=${showData.show?.shows_uuid}`)}
                  style={{ cursor: 'pointer' }}
                >
                  Popular Topics
                  <FaChevronRight className={styles.headerChevron} />
                </h2>
              }
              topics={topics.items}
              loading={topicsLoading}
              totalCount={topics.totalCount}
              currentPage={topicsCurrentPage}
              onPageChange={handleTopicsPageChange}
              topicsPerPage={topicsPerPage}
              includePagination={true}
              onTopicSelect={handleTagSelect}
            />
          </div>
        )}

        {guests.length > 0 && (
          <div className={styles.guestsSection}>
            <h2 
              className={`${styles.sectionTitle} ${styles.clickableTitle}`}
              onClick={() => setIsGuestModalOpen(true)}
            >
              Guests ({guestsCount}) ›
            </h2>
            <div className={styles.guestsList}>
              {guests.map((guest) => (
                <div key={guest.persons_uuid} className={styles.guestItem}>
                  <Person person={guest} />
                </div>
              ))}
            </div>
            {isGuestModalOpen && (
              <PersonFilterModal
                onClose={() => setIsGuestModalOpen(false)}
                selectedPersons={[]}
                onPersonSelect={handleGuestSelect}
                showsUuids={[uuid]}
                selectedTopics={[]}
              />
            )}
          </div>
        )}
        
        <div className={styles.sectionContainer}>
          <EpisodesList
            title={episodeType === 'all' ? 'Episodes' : 'Episodes'}
            episodes={episodes.items} 
            loading={episodesLoading}
            totalCount={episodes.totalCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            episodesPerPage={episodesPerPage}
            episodeType={episodeType}
            onTypeToggle={handleTypeToggle}
            displayGuests={true}
          />
        </div>
      </main>
    </div>
  );
};

export default ShowPage;
