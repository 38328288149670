import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { User } from 'lucide-react';
import { getUserPreferences, getUserHistory } from '../api/api';
import { clearUser } from '../store/authSlice';
import { auth } from '../firebase';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/UserProfile.module.css';
import { FaHistory } from 'react-icons/fa';
import QueueExcerptsTable from './QueueExcerptsTable';
import EpisodesScroller from './EpisodesScroller';
import { createPortal } from 'react-dom';
import ExcerptPlayer from './ExcerptPlayer';

function UserProfile() {
  const { user, isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [preferences, setPreferences] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const [historyData, setHistoryData] = useState(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [historyError, setHistoryError] = useState(null);
  const [historyPage, setHistoryPage] = useState(1);
  const [historyLimit] = useState(10);
  const [selectedExcerpt, setSelectedExcerpt] = useState(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayRoot, setOverlayRoot] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated) {
        fetchUserPreferences();
        fetchUserHistory();
      } else {
        navigate('/auth');
      }
    }
  }, [isAuthenticated, authLoading, navigate]);

  useEffect(() => {
    if (isAuthenticated && !authLoading) {
      fetchUserHistory();
    }
  }, [historyPage]);

  useEffect(() => {
    setOverlayRoot(document.getElementById('overlay-root'));
  }, []);

  const fetchUserPreferences = async () => {
    setIsLoadingPreferences(true);
    try {
      const data = await getUserPreferences();
      setPreferences(data);
    } catch (error) {
      setError(error.message || 'An error occurred while fetching user preferences');
    } finally {
      setIsLoadingPreferences(false);
    }
  };

  const fetchUserHistory = async () => {
    setIsLoadingHistory(true);
    try {
      const data = await getUserHistory({ limit: historyLimit, page: historyPage });
      setHistoryData(data);
      setHistoryError(null);
    } catch (error) {
      setHistoryError(error.message || 'An error occurred while fetching user history');
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      dispatch(clearUser());
      navigate('/auth');
    });
  };

  const handleHistoryPageChange = (newPage) => {
    setHistoryPage(newPage);
  };

  const handleExcerptClick = (excerpt) => {
    setSelectedExcerpt(excerpt);
    setIsOverlayOpen(true);
  };

  const handleCloseExcerpt = () => {
    setSelectedExcerpt(null);
    setIsOverlayOpen(false);
  };

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  const ProfileImage = ({ user }) => {
    const [imageError, setImageError] = useState(false);

    if (!user.photoURL || imageError) {
      return (
        <div className={styles.initialsAvatar}>
          {getInitials(user.displayName)}
        </div>
      );
    }

    return (
      <img 
        src={user.photoURL} 
        alt="Profile" 
        className={styles.profileImage}
        onError={() => setImageError(true)}
      />
    );
  };

  const renderOverlay = () => {
    if (!selectedExcerpt || !overlayRoot || !isOverlayOpen) return null;

    return createPortal(
      <div 
        className={styles.overlay} 
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleCloseExcerpt();
          }
        }}
      >
        <div className={styles.overlayContent}>
          <ExcerptPlayer
            key={selectedExcerpt.excerpt.excerpts_uuid}
            excerpt={selectedExcerpt}
            startPlaying={true}
          />
          <button className={styles.closeButton} onClick={handleCloseExcerpt}>
            ×
          </button>
        </div>
      </div>,
      overlayRoot
    );
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <main className={styles.container}>
      <PageHelmet title="Profile" />
      
      <div className={styles.header}>
        <h1 className={styles.title}>Your Profile</h1>
      </div>
      
      <section className={styles.section}>
        <div className={styles.titleContainer}>
          <FaHistory className={styles.sectionIcon} />
          <h2 className={styles.sectionTitle}>
            Listening History
          </h2>
        </div>
        
        {isLoadingHistory ? (
          <div className={styles.loadingContainer}>
            <LoadingSpinner text="Loading history..." />
          </div>
        ) : (
          <>
          <h3 className={styles.subsectionTitle}>Recent Episodes</h3>
          {historyData?.history?.episodes && historyData?.history?.episodes.length > 0 ? (
              <>
                <EpisodesScroller episodes={historyData.history.episodes} />
                <div className={styles.divider}></div>
              </>
            ) : (
              <div className={styles.noResults}>
                No episodes found in your listening history
              </div>
            )}
            <h3 className={styles.subsectionTitle}>Recent Excerpts</h3>
            <div className={styles.tableContainer}>
              {historyData?.history?.excerpts && historyData.history.excerpts.length > 0 ? (
                <QueueExcerptsTable 
                  excerpts={historyData.history.excerpts}
                  pagination={historyData.pagination}
                  onPageChange={handleHistoryPageChange}
                  onSelectExcerpt={handleExcerptClick}
                  onExcerptRemoved={undefined}
                  deletingExcerpts={[]}
                />
              ) : (
                <div className={styles.noResults}>
                  No excerpts found in your listening history
                </div>
              )}
            </div>
          </>
        )}
      </section>

      <section className={styles.section}>
        <div className={styles.titleContainer}>
          <User className={styles.sectionIcon} />
          <h2 className={styles.sectionTitle}>Account Details</h2>
        </div>
        <div className={styles.profileContent}>
          <ProfileImage user={user} />
          <p className={styles.infoText}>
            <span className={styles.infoLabel}>Name:</span> {user.displayName || 'N/A'}
          </p>
          <p className={styles.infoText}>
            <span className={styles.infoLabel}>Email:</span> {user.email}
          </p>
          <button onClick={handleSignOut} className={styles.signOutButton}>
            Sign Out
          </button>
        </div>
      </section>
      {renderOverlay()}
    </main>
  );
}

export default UserProfile;
