import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/EpisodesScroller.module.css';

function EpisodesScroller({ episodes }) {
  const navigate = useNavigate();
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (episodeUuid) => {
    setImageErrors(prev => ({
      ...prev,
      [episodeUuid]: true
    }));
  };

  const handleEpisodeClick = (episode) => {
    navigate(`/episodes/${episode.episodes_uuid}`);
  };

  if (!episodes || episodes.length === 0) {
    return null;
  }

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.episodesList}>
        {episodes.map((episodeData) => {
          const episodeUuid = episodeData.episode.episodes_uuid;
          const imageUrl = imageErrors[episodeUuid] || !episodeData.episode.episode_image
            ? '/placeholder.webp'
            : episodeData.episode.episode_image;

          return (
            <div
              key={episodeUuid}
              className={styles.episodeCard}
              onClick={() => handleEpisodeClick(episodeData.episode)}
            >
              <div className={styles.episodeImageContainer}>
                <img
                  src={imageUrl}
                  alt={episodeData.episode.title || 'Episode'}
                  className={styles.episodeImage}
                  onError={() => handleImageError(episodeUuid)}
                />
              </div>
              <div className={styles.episodeInfo}>
                <h4 className={styles.episodeTitle}>{episodeData.episode.title}</h4>
                <p className={styles.episodeShow}>{episodeData.episode.show_title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EpisodesScroller; 